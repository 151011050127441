import { getUserCountry, getCurrentStoreRegion, isCountryInRegion, isPreferredStore, getSuggestedStore, sessionKeys } from '../theme-scripts.js';

class StoreSelectorPopover extends HTMLElement {
  constructor() {
    super();
    this.sessionKeys = sessionKeys;
    this.currentStoreUrl = window.currentShop.url;
  }

  connectedCallback() {
    this.initializeElements();
    this.init();
    this.initializeState();
    this.setupEventListeners();
  }

  initializeElements() {
    this.redirectBtn = this.querySelector('[data-redirect-button]');
    this.stayBtn = this.querySelector('[data-stay-button]');
  }

  initializeState() {
    this.hasBeenAskedOrRedirectedAlready = this.getSessionItem(this.sessionKeys.hasBeenAsked);
    this.isPreferredStore = isPreferredStore(this.currentUserCountryCode, this.currentStoreUrl);
  }

  setupEventListeners() {
    this.redirectBtn?.addEventListener('click', () => this.handleRedirect());
    this.stayBtn?.addEventListener('click', () => this.dismissModal());
  }

  async init() {
    // Set up environment flags
    this.isTestMode = this.hasAttribute('data-testmode');
    this.isStagingMode = this.currentStoreUrl.includes('myshopify.com');
    this.isDesignMode = Shopify.designMode;

    // Get user and store location data
    await this.initializeLocationData();

    // Get suggested store data using the utility function
    const storeData = getSuggestedStore({
      currentStoreUrl: this.currentStoreUrl,
      currentUserCountryCode: this.currentUserCountryCode,
      currentStoreCountryCode: this.currentStoreCountryCode,
      isTestMode: this.isTestMode,
      isStagingMode: this.isStagingMode,
    });

    console.log(storeData);

    if (this.isTestMode) {
      this.handleStorePreference(storeData);
    }

    // Early return if user has already been handled
    if (this.hasBeenAskedOrRedirectedAlready || this.isPreferredStore) {
      return;
    }

    // Handle normal store preference logic
    this.handleStorePreference(storeData);
  }

  async initializeLocationData() {
    this.currentUserCountryCode = await getUserCountry();
    this.currentStoreCountryCode = getCurrentStoreRegion(this.currentStoreUrl);
  }

  handleStorePreference(storeData) {
    const currentRegion = window.storeSelectorConfig.regions[this.currentStoreCountryCode];

    // Set preferred store status
    if (this.isUserInCurrentRegion(currentRegion)) {
      sessionStorage.setItem(this.sessionKeys.isPreferred, 'true');
    } else {
      sessionStorage.removeItem(this.sessionKeys.isPreferred);
    }

    this.handleUserRedirect(storeData);
  }

  isUserInCurrentRegion(currentRegion) {
    return currentRegion && isCountryInRegion(this.currentUserCountryCode, this.currentStoreCountryCode) && currentRegion.suggestedStoreUrl === this.currentStoreUrl;
  }

  handleUserRedirect(storeData) {
    // Early return if popup should not be shown
    if (!storeData?.showRedirectPopup && storeData?.popoverDisabled) {
      return;
    }

    // Update content and show popover
    this.replaceModalContent(storeData);
    this.showPopover();
  }

  replaceModalContent(storeData) {
    const elements = {
      heading: this.querySelector('[data-heading]'),
      body: this.querySelector('[data-body]'),
      redirectBtn: this.querySelector('[data-redirect-button]'),
      stayBtn: this.querySelector('[data-stay-button]'),
    };

    for (const [key, element] of Object.entries(elements)) {
      if (!element) continue;

      let content = element.innerHTML;
      const currentRegion = window.storeSelectorConfig.regions[this.currentStoreCountryCode];
      const suggestedRegion = window.storeSelectorConfig.regions[storeData.suggestedStoreCountryCode];

      const currentDisplayName = currentRegion?.displayName || this.currentStoreCountryCode || 'Current Store';
      const suggestedDisplayName = storeData.isStoreLocator ? 'Store Locator' : storeData.suggestedStoreRegion || suggestedRegion?.displayName || storeData.suggestedStoreCountryCode;

      content = content.replace(/\{current_store\}/g, currentDisplayName).replace(/\{suggested_store\}/g, suggestedDisplayName);

      element.innerHTML = content;
    }

    // Set the redirect URL
    if (elements.redirectBtn) {
      elements.redirectBtn.href = storeData.suggestedStoreUrl || window.currentShop.storeLocatorUrl;
    }
  }

  dismissModal() {
    sessionStorage.setItem(this.sessionKeys.hasBeenAsked, 'true');
    this.hidePopover();
  }

  handleRedirect() {
    sessionStorage.setItem(this.sessionKeys.hasBeenAsked, 'true');
  }

  // Utility methods
  getSessionItem(key) {
    return sessionStorage.getItem(key) === 'true';
  }

  showPopover() {
    if (this.hasAttribute('popover')) {
      this.togglePopover(true);
    }
  }

  hidePopover() {
    if (this.hasAttribute('popover')) {
      this.togglePopover(false);
    }
  }
}

customElements.define('store-selector-popover', StoreSelectorPopover);
